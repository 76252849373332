// src/components/FeedForm.tsx

import React, { useState } from "react";

interface FeedFormProps {
  onSubmit: (oldFeed: File, newFeed: File) => void;
}

const FeedForm: React.FC<FeedFormProps> = ({ onSubmit }) => {
  const [oldFeed, setOldFeed] = useState<File | null>(null);
  const [newFeed, setNewFeed] = useState<File | null>(null);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (oldFeed && newFeed) {
      onSubmit(oldFeed, newFeed);
    } else {
      alert("Prosím, nahraj oba XML soubory.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="old_feed">Původní/starý feed:</label>
        <input
          type="file"
          id="old_feed"
          accept=".xml"
          onChange={(e) =>
            setOldFeed(e.target.files ? e.target.files[0] : null)
          }
          required
        />
      </div>
      <div>
        <label htmlFor="new_feed">Současný feed:</label>
        <input
          type="file"
          id="new_feed"
          accept=".xml"
          onChange={(e) =>
            setNewFeed(e.target.files ? e.target.files[0] : null)
          }
          required
        />
      </div>
      <div>
        <button type="submit">Porovnat</button>
      </div>
    </form>
  );
};

export default FeedForm;
