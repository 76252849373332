import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import XMLFeedAnalysis from './XMLFeedAnalysis';
import ROASPNOCalculator from './ROASPNOCalculator';
import NotFound from './components/NotFound';
import './App.css';

// Komponenta, která kontroluje URL na přítomnost "roas" nebo "pno"
const RouteWithROASPNOCheck: React.FC = () => {
    const location = useLocation();
    const roasPnoRegex = /roas|pno/i; // Regulární výraz pro kontrolu "roas" nebo "pno")

    if (roasPnoRegex.test(location.pathname)) {
        return <ROASPNOCalculator />;
    }

    return <NotFound />;
};

const App: React.FC = () => {
    return (
        <Router>
            <div className="MainApp">
                <Navbar />
                <Routes>
                    <Route path="/" element={<XMLFeedAnalysis />} />
                    {/* Pokud URL obsahuje "roas" nebo "pno", vykreslí se ROASPNOCalculator */}
                    <Route path="/*" element={<RouteWithROASPNOCheck />} />
                    {/* Vše ostatní jde na NotFound */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
