import React, { useState } from "react";
import "./ROASPNOCalculator.css"; // Import CSS souboru pro styly

const ROASPNOCalculator: React.FC = () => {
  // const [conversionType, setConversionType] = useState<string>("pnoToRoas");
  const [inputValue, setInputValue] = useState<string>("");
  const [result, setResult] = useState<string>("Zadej číslo");
  const [isValidResult, setIsValidResult] = useState<boolean>(false);

  const calculateResult = (value: string) => {
    let resultValue;
    const inputNumber = parseFloat(value);

    if (isNaN(inputNumber)) {
      setResult("Zadej platné číslo");
      setIsValidResult(false);
    } else if (inputNumber === 0) {
      setResult("Tak logicky... když tu dáš nulu, tak to bude zase nula 🤷‍♂️");
      setIsValidResult(false);
    } else if (inputNumber < 0) {
      setResult("Tak do mínusu asi taky nepůjdeme, že jo? 🤷‍♂️");
      setIsValidResult(false);
    } else {
      resultValue = ((100 / inputNumber) * 100).toFixed(2);
      setResult(`Přepočet: ${resultValue}`);
      setIsValidResult(true);
    }
  };

  // const handleConversionTypeChange = (
  //   e: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   const newType = e.target.value;
  //   setConversionType(newType);
  //   setInputValue("");
  //   setResult("Zadej číslo");
  //   setIsValidResult(false);
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    calculateResult(newValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Zamezíme odeslání formuláře
  };

  return (
    <div className="App">
      <h2>Převodník PNO-ROAS</h2>
      <form onSubmit={handleSubmit}>
        {/* <div>
          <label htmlFor="conversionType">Vyber:</label>
          <select
            id="conversionType"
            value={conversionType}
            onChange={handleConversionTypeChange}
          >
            <option value="pnoToRoas">PNO -&gt; ROAS</option>
            <option value="roasToPno">ROAS -&gt; PNO</option>
          </select>
        </div> */}
        <div>
          <label htmlFor="inputValue">Zadej hodnotu:</label>
          <input
            type="number"
            id="inputValue"
            value={inputValue}
            onChange={handleInputChange}
            step="any"
            required
          />
        </div>
      </form>
      <div id="result" className={isValidResult ? "result-button" : ""}>
        {result}
      </div>
    </div>
  );
};

export default ROASPNOCalculator;
