// src/XMLFeedAnalysis.tsx

import React, { useState } from "react";
import FeedForm from "./components/FeedForm";
import Results from "./components/Results";
import { parseFeed } from "./utils/feedParser";
import { compareFeeds } from "./utils/utils";
import "./XMLFeedAnalysis.css";

const XMLFeedAnalysis: React.FC = () => {
  const [missingProducts, setMissingProducts] = useState<{
    [id: string]: string;
  }>({});
  const [isProcessingComplete, setIsProcessingComplete] =
    useState<boolean>(false);

  const handleFormSubmit = async (oldFeed: File, newFeed: File) => {
    setIsProcessingComplete(false);

    try {
      const oldFeedContent = await oldFeed.text();
      const newFeedContent = await newFeed.text();

      const oldProducts = parseFeed(oldFeedContent);
      const newProducts = parseFeed(newFeedContent);
      const missing = compareFeeds(oldProducts, newProducts);

      setMissingProducts(missing);
    } catch (error) {
      console.error("Chyba při zpracování souborů:", error);
    } finally {
      setIsProcessingComplete(true);
    }
  };

  const handleReset = () => {
    setMissingProducts({});
    setIsProcessingComplete(false);
  };

  return (
    <div className="App">
      <h1>Nahraj XML feedy pro porovnání</h1>
      <p className="supported-feeds">
        Podporuje feedy: Google Nákupy, Pemacz, Heureka
      </p>
      <FeedForm onSubmit={handleFormSubmit} />
      <Results
        missingProducts={missingProducts}
        isProcessingComplete={isProcessingComplete}
        onReset={handleReset}
      />
    </div>
  );
};

export default XMLFeedAnalysis;
