// src/components/Results.tsx

import React from "react";
import { downloadCSV } from "../utils/utils";
import "../XMLFeedAnalysis.css";

interface ResultsProps {
  missingProducts: { [id: string]: string };
  isProcessingComplete: boolean;
  onReset: () => void;
}

const Results: React.FC<ResultsProps> = ({
  missingProducts,
  isProcessingComplete,
  onReset,
}) => {
  const handleDownloadCSV = () => {
    downloadCSV(missingProducts);
  };

  if (!isProcessingComplete) {
    return null; // Během zpracování neukazujeme nic
  }

  const renderButtons = () => (
    <div className="button-group">
      <button className="reset-button-style" onClick={handleDownloadCSV}>Stáhnout CSV</button>
      <button onClick={onReset}>Reset</button>
    </div>
  );

  return (
    <div>
      {Object.keys(missingProducts).length === 0 ? (
        <p>Žádné produkty nechybí.</p>
      ) : (
        <>
          {Object.keys(missingProducts).length > 25 && renderButtons()}
          <p className="supported-feeds">
            Celkem chybí {Object.keys(missingProducts).length} produktů.
          </p>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Název</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(missingProducts).map(([id, title]) => (
                <tr key={id}>
                  <td>{id}</td>
                  <td>{title}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {renderButtons()}
        </>
      )}
    </div>
  );
};

export default Results;
