// src/utils/feedParser.ts

export function parseGoogleFeed(feedContent: string): { [id: string]: string } {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(feedContent, "application/xml");
  const items = xmlDoc.getElementsByTagName("item");
  const products: { [id: string]: string } = {};
  for (let item of items) {
    const id = item.getElementsByTagName("g:id")[0]?.textContent; //|| item.getElementsByTagName("id")[0]?.textContent;
    const title =
      item.getElementsByTagName("title")[0]?.textContent ||
      item.getElementsByTagName("g:title")[0]?.textContent;
    if (id && title) {
      products[id] = title;
    }
  }
  return products;
}

export function parsePemaczFeed(feedContent: string): { [id: string]: string } {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(feedContent, "application/xml");
  const items =
    xmlDoc.getElementsByTagName("entry") ||
    xmlDoc.getElementsByTagName("atom:entry");
  const products: { [id: string]: string } = {};
  for (let item of items) {
    const id =
      item.getElementsByTagName("g:id")[0]?.textContent ||
      item.getElementsByTagName("id")[0]?.textContent;
    const title =
      item.getElementsByTagName("g:title")[0]?.textContent ||
      item.getElementsByTagName("title")[0]?.textContent;
    if (id && title) {
      products[id] = title;
    }
  }
  return products;
}

export function parseHeurekaFeed(feedContent: string): {
  [id: string]: string;
} {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(feedContent, "application/xml");
  const items = xmlDoc.getElementsByTagName("SHOPITEM");
  const products: { [id: string]: string } = {};
  for (let item of items) {
    const id = item.getElementsByTagName("ITEM_ID")[0]?.textContent;
    const title = item.getElementsByTagName("PRODUCTNAME")[0]?.textContent;
    if (id && title) {
      products[id] = title;
    }
  }
  return products;
}

// src/utils/feedParser.ts

export function detectFeedType(feedContent: string): string {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(feedContent, "application/xml");

  if (xmlDoc.getElementsByTagName("item").length > 0) {
    return "google";
  } else if (
    xmlDoc.getElementsByTagName("entry").length > 0 ||
    xmlDoc.getElementsByTagName("atom:entry").length > 0
  ) {
    return "pemacz";
  } else if (xmlDoc.getElementsByTagName("SHOPITEM").length > 0) {
    return "heureka";
  } else {
    throw new Error("Neznámý typ feedu");
  }
}

export function parseFeed(feedContent: string): { [id: string]: string } {
  const feedType = detectFeedType(feedContent);

  switch (feedType) {
    case "google":
      return parseGoogleFeed(feedContent);
    case "pemacz":
      return parsePemaczFeed(feedContent);
    case "heureka":
      return parseHeurekaFeed(feedContent);
    default:
      throw new Error("Neznámý typ feedu");
  }
}
