// src/components/NotFound.tsx
import React from "react";
import { useLocation } from "react-router-dom";
import "./NotFound.css";

const NotFound: React.FC = () => {
  const location = useLocation();

  //odebrání lomítka z URL
  let path = location.pathname;
  if (path.includes("/")) {
    path = path.substring(1);
  }

  //dekódování URL - pokud by byly v URL speciální znaky
  const decodedPathName = decodeURIComponent(path);

  return (
    <div className="NotFound">
      <h2>
        Ahoj. Mám pro tebe špatnou zprávu, stránka{" "}
        <span className="highlight">{decodedPathName}</span> neexistuje, trošku
        ses sekl...
      </h2>
      <p>
        Použij prosím jednu z možností z horní navigace pro přechod do appky.
      </p>
    </div>
  );
};

export default NotFound;
