// src/utils/utils.ts
export function downloadCSV(missingProducts: { [id: string]: string }) {
  const csvRows: string[] = [];

  // Přidání hlavičky CSV
  csvRows.push("ID,Název");

  // Přidání dat s ohraničením hodnot v uvozovkách
  for (const [id, title] of Object.entries(missingProducts)) {
    // Uložit hodnoty do uvozovek, aby se předešlo problémům s čárkami
    csvRows.push(`"${id}","${title.replace(/"/g, '""')}"`);
  }

  // Sestavení CSV obsahu
  const csvContent = csvRows.join("\n");
  const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "missing_products.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// Přidejte implementaci compareFeeds
export function compareFeeds(
  oldProducts: { [id: string]: string },
  newProducts: { [id: string]: string }
) {
  const missingProducts: { [id: string]: string } = {};
  for (const [id, title] of Object.entries(oldProducts)) {
    if (!newProducts[id]) {
      missingProducts[id] = title;
    }
  }
  return missingProducts;
}
