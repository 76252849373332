import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css"; // Přidáme odkaz na nový CSS soubor

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className="navbar-item">
          <Link className="navbar-link" to="/">
            Analýza XML feedů
          </Link>
        </li>
        <li className="navbar-item">
          <Link className="navbar-link" to="/roas-pno">
            ROAS-PNO Kalkulačka
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
